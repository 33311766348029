import { gql } from '@apollo/client'

const GET_REQUEST = gql`
  query GetRequest($id: ID!) {
    request(id: $id) {
      id
      payload
      traceableId
      traceableType
      responses {
        id
        payload
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

export default GET_REQUEST
