import { gql } from '@apollo/client'

const GET_EVENT_LOGS = gql`
  query GetEventLogs($objectId: ID!, $objectType: String!) {
    eventLogs(objectId: $objectId, objectType: $objectType) {
      id
      title
      message
      createdAt
    }
  }
`

export default GET_EVENT_LOGS
