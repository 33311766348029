import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Error from '@components/Error/Error'
import Heading from '@components/heading/heading'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router'
import UPDATE_APPLICATION from '@graphql/mutations/update-application'
import GET_APPLICATION from '@graphql/queries/get-application'
import { toast } from 'react-toastify'
import ToastMessage from '@components/ToastMessage/ToastMessage'
import Spinner from 'react-bootstrap/Spinner'

function Edit() {
  const navigate = useNavigate()
  const { applicationId } = useParams()
  const [applicationName, setApplicationName] = useState('')
  const application = useQuery(GET_APPLICATION, {
    variables: { id: applicationId },
    onCompleted: (data) => setApplicationName(data.application.name)
  })
  const [updateApplication, { data, loading, error }] = useMutation(UPDATE_APPLICATION, {
    onCompleted() {
      navigate(`/applications/${applicationId}`)
      toast(<ToastMessage message='Application name updated!' />)
    },
    onError() {}
  })

  return (
    <>
      <Heading heading='Edit Application' />
      { error && (
        <Error error={error} />
      ) }
      <Card>
        <div className='heading-6'>Application Configuration</div>
        <Card.Body>
          <Form.Group className='mb-3' controlId='formGroupName'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              name='name'
              defaultValue={applicationName}
              onChange={(event) => setApplicationName(event.target.value)}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className='text-center'>
          <Button
            variant='success'
            disabled={loading}
            onClick={() => updateApplication({ variables: { id: applicationId, name: applicationName } })}
          >
            Submit
            {loading && (
              <Spinner as='span' animation='border' role='status' size='sm' className='ms-1' />
            )}
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default Edit
