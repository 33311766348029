import React from 'react'
import { NavLink } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { user } from '@cache'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Logo from '@components/logo/logo'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import User from '@utils/storage/user'

function Navigation() {
  const currentUser = useReactiveVar(user)

  if (!currentUser) { return '' }

  return (
    <Navbar variant='dark'>
      <Logo />
      <Nav className='me-auto'>
        <Nav.Item>
          <NavLink to='/applications' className='nav-link'>Applications</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to='/tokens' className='nav-link'>Tokens</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to='/operations' className='nav-link'>Operations</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to='/transactions' className='nav-link'>Transactions</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to='/requests' className='nav-link'>Requests</NavLink>
        </Nav.Item>
      </Nav>
      <Navbar.Toggle />
      <Navbar.Collapse className='justify-content-end'>
        <Navbar.Text>
          <Button
            className='logout'
            variant='link'
            onClick={() => { User.clear() }}
          >
            Logout
            <Badge bg='primary'>{User.get().login}</Badge>
          </Button>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
