import React from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

function Navigate({ children, variant, to }) {
  const navigate = useNavigate()

  return (
    <Button size='sm' variant={variant} onClick={() => navigate(to)}>
      {children}
    </Button>
  )
}

Navigate.defaultProps = {
  variant: 'primary'
}

export default Navigate
