import React from 'react'
import Alert from 'react-bootstrap/Alert'

function ToastMessage({ message, variant }) {
  return (
    <Alert variant={variant} className='d-flex align-items-center justify-content-center'>
      {message}
    </Alert>
  )
}

ToastMessage.defaultProps = {
  variant: 'warning'
}

export default ToastMessage
