import { gql } from '@apollo/client'

const REVOKE_TOKEN = gql`
  mutation RevokeToken($id: ID!) {
    revokeToken(id: $id) {
      id
      token
      application {
        id
        name
      }
      active
      activeUntil
      updatedAt
      createdAt
    }
  }
`

export default REVOKE_TOKEN
