import { gql } from '@apollo/client'

const GET_TOKEN = gql`
  query GetToken($id: ID!) {
    token(id: $id) {
      id
      token
      application {
        id
        name
      }
      active
      activeUntil
      createdAt
      updatedAt
    }
  }
`

export default GET_TOKEN
