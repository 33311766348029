import { gql } from '@apollo/client'
import ITEMS_PER_PAGE from '@utils/constants'

const GET_APPLICATIONS_FOR_SELECT = gql`
  query GetApplicationsForSelect {
    applicationsForSelect {
      id
      name
    }
  }
`

export default GET_APPLICATIONS_FOR_SELECT
