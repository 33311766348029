import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Heading from '@components/heading/heading'
import StatusBadge from '@components/operations/status-badge'
import SagaTransactionHierarchyTree from '@components/saga-transaction-hierarchy/tree'
import EventLogsTable from '@components/event-logs/table'
import GET_OPERATION from '@graphql/queries/get-operation'
import Error from '@components/Error/Error'

function Preview() {
  const { operationId } = useParams()
  const { data, error, loading } = useQuery(GET_OPERATION, { variables: { id: operationId } })
  const operation = data?.operation

  return (
    <>
      <Heading heading='Operation Details' />
      { error && (
        <Error error={error} />
      ) }
      { operation
          && (
            <>
              <SagaTransactionHierarchyTree operationId={operationId} />
              <br />
              <div className='table-responsive-margin-b-0'>
                <Table responsive bordered hover striped>
                  <thead>
                    <tr>
                      <td colSpan='2'>Operation Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{operation.id}</td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>{operation.name}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <StatusBadge status={operation.status} />
                      </td>
                    </tr>
                    <tr>
                      <th>Merchant</th>
                      <td>{operation.merchantName}</td>
                    </tr>
                    <tr>
                      <th>Application</th>
                      <td>
                        <Link to={`/applications/${operation.application.id}`}>
                          {operation.application.name}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Request</th>
                      <td>
                        { operation.request && (
                        <Link to={`/requests/${operation.request.id}`}>
                          {`Request (${operation.request.id})`}
                        </Link>
                        ) }
                      </td>
                    </tr>
                    <tr>
                      <th>Created at</th>
                      <td>{operation.createdAt}</td>
                    </tr>
                    <tr>
                      <th>Updated at</th>
                      <td>{operation.updatedAt}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <br />
              <EventLogsTable objectId={operationId} objectType='Operation' />
            </>
          )}
    </>
  )
}

export default Preview
