import { gql } from '@apollo/client'

const CREATE_TOKEN = gql`
  mutation CreateToken($applicationId: String!, $activeUntil: String) {
    createToken(applicationId: $applicationId, activeUntil: $activeUntil) {
      token
    }
  }
`

export default CREATE_TOKEN
