import { gql } from '@apollo/client'
import ITEMS_PER_PAGE from '@utils/constants'

const GET_REQUESTS = gql`
  query GetRequests($filters: JSON) {
    requests(filters: $filters) {
      collection {
        id
        payload
        traceableId
        traceableType
        createdAt
        updatedAt
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_REQUESTS
