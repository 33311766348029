import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ExternalAuthentication from '@utils/external-authentication'
import Row from 'react-bootstrap/Row'
import User from '@utils/storage/user'
import LOGIN_BY_AUTH_CODE from '@graphql/mutations/login-by-auth-code'
import { toast } from 'react-toastify'
import ToastMessage from '@components/ToastMessage/ToastMessage'
import Spinner from 'react-bootstrap/Spinner'

function LoginByAuthCode() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loginByAuthCode, { data, loading, error }] = useMutation(LOGIN_BY_AUTH_CODE, {
    onCompleted(data) {
      User.set({ login: data.loginByAuthCode.login, role: data.loginByAuthCode.role })
      navigate('/applications')
      toast(<ToastMessage message='Logged in!' />)
    },
    onError() { toast(<ToastMessage message="Authentication wasn't successful!" variant='danger' />) }
  })

  useEffect(() => {
    loginByAuthCode({ variables: { accessCode: searchParams.get('code') } })
  }, [])

  return (
    <>
      { loading && <Spinner animation='border' size='sm' variant='dark' /> }
      { error && (
        <Container>
          <Card className='mt-3'>
            <Card.Body>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <Alert variant='danger'>{error.message}</Alert>
                  <p>Authentication wasn't successful! Please try to log in again:</p>
                  <div className='d-grid gap-2'>
                    <Button
                      variant='success'
                      onClick={() => ExternalAuthentication.redirect()}
                    >
                      Log in
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      ) }
    </>
  )
}

export default LoginByAuthCode
