import React from 'react'
import { useQuery } from '@apollo/client'
import Select from 'react-select'
import GET_APPLICATIONS_FOR_SELECT from '@graphql/queries/get-applications-for-select'

function ApplicationsSelect({ name, value, onChange }) {
  const applications = useQuery(GET_APPLICATIONS_FOR_SELECT)
  const applicationsOption = applications?.data?.applicationsForSelect.map((app) => (
    { label: app.name, value: app.id }
  )) || []

  const valueForSelect = (selected) => (
    applicationsOption.filter(({ value }) => value === selected)
  )

  return (
    <Select
      name={name}
      value={valueForSelect(value)}
      options={applicationsOption}
      onChange={(event) => onChange(event)}
    />
  )
}

ApplicationsSelect.defaultProps = {
  name: 'applicationId'
}

export default ApplicationsSelect
