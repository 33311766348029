import React from 'react'

function ExternalCreatedObjectLink({ sagaTransaction }) {
  return (
    sagaTransaction.createdObjectUrl
      ? (
        <a href={sagaTransaction.createdObjectUrl} target='blank'>
          {sagaTransaction.type.replace('SagaTransaction', '')} (ID: {sagaTransaction.createdObjectId})
        </a>
      )
      : null)
}

export default ExternalCreatedObjectLink
