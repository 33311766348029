import React from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import StatusBadge from '@components/saga-transactions/status-badge'
import GET_SAGA_TRANSACTION_HIERARCHY from '@graphql/queries/get-saga-transaction-hierarchy'

function SagaTransactionHierarchyTree({ operationId, sagaTransactionId }) {
  const { data } = useQuery(GET_SAGA_TRANSACTION_HIERARCHY, {
    variables: { operationId }
  })

  const renderNode = (current) => (
    <div className='tree-node' key={current.id}>
      <div className='tree-node-name'>
        <span className='tree-node-id'>#{ current.id }</span>
        { sagaTransactionId === current.id
          ? current.type
          : (
            <Link to={`/transactions/${current.id}`}>
              {current.type}
            </Link>
          )}
        {' '}<StatusBadge status={current.status} />
      </div>
      <div className='tree-node-connection'>
        <div className='tree-node-dot' />
      </div>

      { current.children?.length ? current.children.map(renderNode) : ''}
    </div>
  )

  if (data?.sagaTransactionHierarchy.length === 0) {
    return (
      <Alert key='warning' variant='warning'>
        There are no Saga Transactions associated
      </Alert>
    )
  }

  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>Transaction Hierarchy</Accordion.Header>
        <Accordion.Body>
          <div className='tree'>
            <div className='tree-root' />
            { data && data.sagaTransactionHierarchy.map((node) => renderNode(node)) }
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default SagaTransactionHierarchyTree
