import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import StatusBadge from '@components/tokens/status-badge'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import User from '@utils/storage/user'

function TokensTable({ tokens, totalCount }) {
  return (
    <>
      <div className='total-count-wrapper'>{`Tokens count: ${totalCount}`}</div>
      <Table responsive bordered hover striped>
        <thead>
          <tr>
            <th className='table-col-sm'>Action</th>
            <th className='table-col-sm'>ID</th>
            <th>Token</th>
            <th>Status</th>
            <th>Application</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          { tokens.map((token) => (
            <tr key={token.id}>
              <td>
                <DropdownButton variant='outline-secondary' title='Actions' size='sm'>
                  <Link to={`/tokens/${token.id}`} className='dropdown-item'>Show</Link>
                </DropdownButton>
              </td>
              <td>{token.id}</td>
              <td>
                <Link to={`/tokens/${token.id}`}>
                  {token.token}
                </Link>
              </td>
              <td>
                <StatusBadge active={token.active} />
              </td>
              <td>
                <Link to={`/applications/${token.application.id}`}>
                  {token.application.name}
                </Link>
              </td>
              <td>{token.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

TokensTable.defaultProps = {
  totalCount: 0
}

export default TokensTable
