import React, { useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ExternalAuthentication from '@utils/external-authentication'

function Login() {
  useEffect(() => {
    ExternalAuthentication.redirect()
  }, [])

  return (
    <Spinner animation='border' size='sm' variant='dark' />
  )
}

export default Login
