import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import ApplicationsSelect from '@components/applications/select'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import DatetimeField from '@components/datetime-field'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import SearchActions from '@components/search/actions'
import Select from 'react-select'
import operation from '@utils/constants/operation'

function Search({ refetch, searchFilters, setSearchParams }) {
  const [filters, setFilters] = useState({ ...operation.SEARCH_FILTERS, ...searchFilters })
  const valueForSelect = (options, selected) => (
    options.filter(({ value }) => value === selected)
  )

  return (
    <Card>
      <Card.Header>Search</Card.Header>
      <Card.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Created at</Form.Label>
          <Col sm={3}>
            <DatetimeField
              name='created_at_gteq'
              value={filters.created_at_gteq}
              onChange={(name, value) => setFilters({ ...filters, [name]: value })}
            />
          </Col>
          <Col sm={3}>
            <DatetimeField
              name='created_at_lteq'
              value={filters.created_at_lteq}
              onChange={(name, value) => setFilters({ ...filters, [name]: value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Name</Form.Label>
          <Col sm={6}>
            <Select
              name='name_eq'
              value={valueForSelect(operation.TYPES, filters.name_eq)}
              options={operation.TYPES}
              onChange={(event) => setFilters({ ...filters, name_eq: event.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Application</Form.Label>
          <Col sm={6}>
            <ApplicationsSelect
              name='application_id_eq'
              value={filters.application_id_eq}
              onChange={(event) => setFilters({ ...filters, application_id_eq: event.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Status</Form.Label>
          <Col sm={6}>
            <Select
              name='status_eq'
              value={valueForSelect(operation.STATUSES, filters.status_eq)}
              options={operation.STATUSES}
              onChange={(event) => setFilters({ ...filters, status_eq: event.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Merchant</Form.Label>
          <Col sm={6}>
            <Form.Control
              name='merchant_name_eq'
              value={filters.merchant_name_eq}
              onChange={(event) => setFilters({ ...filters, merchant_name_eq: event.target.value })}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(operation.SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default Search
