import React from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import Heading from '@components/heading/heading'
import NavigateButton from '@components/button/navigate'
import Pagination from '@components/pagination'
import Search from '@components/tokens/search'
import Table from '@components/tokens/table'
import GET_TOKENS from '@graphql/queries/get-tokens'
import User from '@utils/storage/user'
import Error from '@components/Error/Error'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, error, loading, refetch } = useQuery(GET_TOKENS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <Heading
        heading='Tokens'
        toolbar={User.hasMasterPrivileges() && <NavigateButton to='/tokens/new'>New token</NavigateButton>}
      />
      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.tokens?.metadata}
          />
          <Table
            totalCount={data?.tokens?.metadata?.totalCount}
            tokens={data?.tokens?.collection || []}
          />
        </>
      )}
    </>
  )
}

export default List
