import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import DatetimeField from '@components/datetime-field'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import SearchActions from '@components/search/actions'
import request from '@utils/constants/request'

function Search({ refetch, searchFilters, setSearchParams }) {
  const [filters, setFilters] = useState({
    ...request.SEARCH_FILTERS,
    ...searchFilters
  })

  const valueForSelect = (options, selected) => (
    options.filter(({ value }) => value === selected)
  )

  return (
    <Card>
      <Card.Header>Search</Card.Header>
      <Card.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Created at</Form.Label>
          <Col sm={3}>
            <DatetimeField
              name='created_at_gteq'
              value={filters.created_at_gteq}
              onChange={(name, value) => setFilters({ ...filters, [name]: value })}
            />
          </Col>
          <Col sm={3}>
            <DatetimeField
              name='created_at_lteq'
              value={filters.created_at_lteq}
              onChange={(name, value) => setFilters({ ...filters, [name]: value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Traceable Type</Form.Label>
          <Col sm={6}>
            <Select
              name='traceable_type_eq'
              value={valueForSelect(request.TRACEABLE_TYPES, filters.traceable_type_eq)}
              options={request.TRACEABLE_TYPES}
              onChange={(event) => (
                setFilters({ ...filters, traceable_type_eq: event.value })
              )}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Traceable ID</Form.Label>
          <Col sm={6}>
            <Form.Control
              name='traceable_id_eq'
              value={filters.traceable_id_eq}
              onChange={(event) => (
                setFilters({ ...filters, traceable_id_eq: event.target.value })
              )}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(request.SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default Search
