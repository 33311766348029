import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ApplicationEdit from '@pages/applications/edit'
import ApplicationNew from '@pages/applications/new'
import ApplicationPreview from '@pages/applications/preview'
import ApplicationsList from '@pages/applications/list'
import ExternalAuthentication from '@pages/external-authentication'
import Layout from '@components/layout/layout'
import Login from '@pages/login/login'
import NotFound from '@pages/not-found/not-found'
import OperationPreview from '@pages/operations/preview'
import OperationsList from '@pages/operations/list'
import PrivateRoute from '@components/routes/private-route'
import RequestPreview from '@pages/requests/preview'
import RequestsList from '@pages/requests/list'
import SagaTransactionPreview from '@pages/saga-transactions/preview'
import SagaTransactionsList from '@pages/saga-transactions/list'
import TokenNew from '@pages/tokens/new'
import TokenPreview from '@pages/tokens/preview'
import TokensList from '@pages/tokens/list'
import RestrictPrivateRoute from './restrict-private-route'

function AppRoutes() {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='authentication/new' element={<ExternalAuthentication />} />

      {/* Configure root / path, what to render? */}
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route element={<RestrictPrivateRoute />}>
            <Route path='tokens/new' element={<TokenNew />} />
            <Route path='applications/new' element={<ApplicationNew />} />
            <Route path='applications/:applicationId/edit' exact element={<ApplicationEdit />} />
          </Route>

          <Route path='/' exact element={<ApplicationsList />} />
          <Route path='applications' exact element={<ApplicationsList />} />
          <Route path='applications/:applicationId' exact element={<ApplicationPreview />} />

          <Route path='tokens/*' exact element={<TokensList />} />
          <Route path='tokens/:tokenId' exact element={<TokenPreview />} />

          <Route path='operations' exact element={<OperationsList />} />
          <Route path='operations/:operationId' exact element={<OperationPreview />} />

          <Route path='transactions' exact element={<SagaTransactionsList />} />
          <Route path='transactions/:sagaTransactionId' exact element={<SagaTransactionPreview />} />

          <Route path='requests' exact element={<RequestsList />} />
          <Route path='requests/:requestId' exact element={<RequestPreview />} />
        </Route>
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
