import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function TableLoader() {
  return (
    <tbody>
      <tr>
        <td className='text-center'>
          <Spinner animation='border' role='status' className='my-2' />
        </td>
      </tr>
    </tbody>
  )
}

export default TableLoader
