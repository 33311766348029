import React from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import Heading from '@components/heading/heading'
import Pagination from '@components/pagination'
import Search from '@components/operations/search'
import Table from '@components/operations/table'
import GET_APPLICATIONS_FOR_SELECT from '@graphql/queries/get-applications-for-select'
import GET_OPERATIONS from '@graphql/queries/get-operations'
import Error from '@components/Error/Error'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_OPERATIONS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <Heading heading='Operations' />
      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.operations?.metadata}
          />
          <Table
            totalCount={data?.operations?.metadata?.totalCount}
            operations={data?.operations?.collection || []}
            loading={loading}
          />
        </>
      )}
    </>
  )
}

export default List
