import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ExternalCreatedObjectLink from '@components/saga-transactions/external-created-object-link'
import StatusBadge from '@components/saga-transactions/status-badge'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import TableLoader from '@components/TableLoader/TableLoader'

function SagaTransactionsTable({ sagaTransactions, totalCount, loading }) {
  return (
    <>
      <div className='total-count-wrapper'>{`Saga Transactions count: ${totalCount}`}</div>
      <Table responsive bordered hover striped>
        {loading ? (
          <TableLoader />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th>ID</th>
                <th>Type</th>
                <th>Status</th>
                <th>Operation</th>
                <th>Merchant</th>
                <th>Created object</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              { sagaTransactions.map((sagaTransaction) => (
                <tr key={sagaTransaction.id}>
                  <td>
                    <DropdownButton variant='outline-secondary' title='Actions' size='sm'>
                      <Link to={`${sagaTransaction.id}`} className='dropdown-item'>Show</Link>
                    </DropdownButton>
                  </td>
                  <td>{sagaTransaction.id}</td>
                  <td>
                    <Link to={`/transactions/${sagaTransaction.id}`}>
                      {sagaTransaction.type}
                    </Link>
                  </td>
                  <td>
                    <StatusBadge status={sagaTransaction.status} />
                  </td>
                  <td>
                    <Link to={`/operations/${sagaTransaction.operation.id}`}>
                      {sagaTransaction.operation.name}
                    </Link>
                  </td>
                  <td>{sagaTransaction.operation.merchantName}</td>
                  <td><ExternalCreatedObjectLink sagaTransaction={sagaTransaction} /></td>
                  <td>{sagaTransaction.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  )
}

SagaTransactionsTable.defaultProps = {
  totalCount: 0
}

export default SagaTransactionsTable
