import React from 'react'
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

function Breadcrumb() {
  const breadcrumbs = useBreadcrumbs()
  const currentCrumb = breadcrumbs.pop()

  return (
    <div id='breadcrumb'>
      { breadcrumbs.map(({ match, breadcrumb }) => (
        <span key={match.pathname}>
          <Link to={match.pathname} className='crumblink'>{breadcrumb}</Link>
        </span>
      )) }
      <span>{currentCrumb.breadcrumb}</span>
    </div>
  )
}

export default Breadcrumb
