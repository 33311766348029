import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { cache } from '@cache'
import User from '@utils/storage/user'

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) { console.warn(`[Network error]: ${networkError}`) }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      console.warn(`[GraphQL error]: Message: ${message},`, ` Extensions: ${extensions}`)

      if (extensions?.code === 'UNAUTHORIZED') {
        User.clear()
      }
    })
  }
})

const httpLink = createHttpLink({
  uri: Settings.GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const apolloClient = new ApolloClient({
  cache,
  link: from([onErrorLink, httpLink])
})

export default apolloClient
