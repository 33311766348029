import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import SearchActions from '@components/search/actions'
import application from '@utils/constants/application'

function Search({ refetch, searchFilters, setSearchParams }) {
  const [filters, setFilters] = useState({
    ...application.SEARCH_FILTERS,
    ...searchFilters
  })

  return (
    <Card>
      <Card.Header>Search</Card.Header>
      <Card.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Name</Form.Label>
          <Col sm={6}>
            <Form.Control
              name='name_cont'
              value={filters.name_cont}
              onChange={(event) => setFilters({ ...filters, name_cont: event.target.value })}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(application.SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default Search
