import { gql } from '@apollo/client'

const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($id: ID!, $name: String!) {
    updateApplication(id: $id, name: $name) {
      id
      name
      tokens {
        id
      }
    }
  }
`

export default UPDATE_APPLICATION
