import React from 'react'
import { Outlet } from 'react-router-dom'
import Container from 'react-bootstrap/Container'

function NotFound() {
  return (
    <Container fluid='md'>
      <p>Error 404!</p>
    </Container>
  )
}

export default NotFound
