const STATUSES = [
  'active',
  'expired'
].map((status) => ({ value: status, label: status }))

const SEARCH_FILTERS = {
  application_id_eq: '',
  status_eq: '',
  created_at_gteq: '',
  created_at_lteq: ''
}

export default { SEARCH_FILTERS, STATUSES }
