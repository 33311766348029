import React from 'react'
import BankLogo from '@assets/logos/bank.svg'
import PSPLogo from '@assets/logos/psp.svg'
import Navbar from 'react-bootstrap/Navbar'

function Logo() {
  const isPspInstance = Settings.INSTANCE === 'PSP'

  return (
    <Navbar.Brand className={!isPspInstance && 'logo-small'}>
      { isPspInstance
        ? <PSPLogo title='psp' />
        : <BankLogo title='bank' /> }
    </Navbar.Brand>
  )
}

export default Logo
