/* eslint no-alert: "off" */

import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Heading from '@components/heading/heading'
import StatusBadge from '@components/tokens/status-badge'
import REVOKE_TOKEN from '@graphql/mutations/revoke-token'
import GET_TOKEN from '@graphql/queries/get-token'
import User from '@utils/storage/user'
import Error from '@components/Error/Error'
import { toast } from 'react-toastify'
import ToastMessage from '@components/ToastMessage/ToastMessage'

function Preview() {
  const { tokenId } = useParams()
  const { data, error, loading } = useQuery(GET_TOKEN, { variables: { id: tokenId } })
  const [revokeToken, tokenMutation] = useMutation(REVOKE_TOKEN, { variables: { id: tokenId },
    onCompleted() {
      toast(<ToastMessage message='Token Revoked' />)
    }
  })

  return (
    <>
      <Heading
        heading='Token Details'
        toolbar={(User.hasMasterPrivileges() && !data?.token?.revokedAt) && (
          <Button
            variant='outline-danger'
            onClick={() => {
              if (global.confirm('Are you sure you want to revoke the token?')) {
                revokeToken()
              }
            }}
          >
            Revoke token
          </Button>
        )}
      />
      { error && (
        <Error error={error} />
      )}
      { data
          && (
            <Table responsive bordered hover striped>
              <thead>
                <tr>
                  <td colSpan='2'>Token Details</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{data.token.id}</td>
                </tr>
                <tr>
                  <th>Token</th>
                  <td>{data.token.token}</td>
                </tr>
                <tr>
                  <th>Application</th>
                  <td>
                    <Link to={`/applications/${data.token.application.id}`}>
                      {data.token.application.name}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <StatusBadge active={data.token.active} />
                  </td>
                </tr>
                <tr>
                  <th>Active until</th>
                  <td>{data.token.activeUntil}</td>
                </tr>
                <tr>
                  <th>Created at</th>
                  <td>{data.token.createdAt}</td>
                </tr>
                <tr>
                  <th>Updated at</th>
                  <td>{data.token.updatedAt}</td>
                </tr>
              </tbody>
            </Table>
          )}
    </>
  )
}

export default Preview
