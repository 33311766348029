import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { user } from '@cache'

function PrivateRoute() {
  const currentUser = useReactiveVar(user)

  return currentUser ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoute
