const TRACEABLE_TYPES = [
  'Operation',
  'SagaTransaction'
].map((type) => ({ value: type, label: type }))

const SEARCH_FILTERS = {
  traceable_id_eq: '',
  traceable_type_eq: '',
  created_at_gteq: '',
  created_at_lteq: ''
}

export default { SEARCH_FILTERS, TRACEABLE_TYPES }
