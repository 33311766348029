import React from 'react'
import Breadcrumb from '@components/breadcrumb/breadcrumb'

function Heading({ heading, toolbar }) {
  return (
    <>
      <div className='heading'>
        <h2>{heading}</h2>
        { toolbar && (
          <div className='heading-toolbar'>
            {toolbar}
          </div>
        ) }
      </div>
      <Breadcrumb />
    </>
  )
}

export default Heading
