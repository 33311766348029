import React, { Component } from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import Heading from '@components/heading/heading'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import NavigateButton from '@components/button/navigate'
import GET_APPLICATION from '@graphql/queries/get-application'
import User from '@utils/storage/user'
import Error from '@components/Error/Error'

function Preview() {
  const { applicationId } = useParams()
  const { data, error, loading } = useQuery(GET_APPLICATION, { variables: { id: applicationId } })

  return (
    <>
      <Heading
        heading='Application Details'
        toolbar={User.hasMasterPrivileges() && <NavigateButton to='edit'>Edit</NavigateButton>}
      />
      { error && (
        <Error error={error} />
      )}
      { data
          && (
            <Table responsive bordered hover striped>
              <thead>
                <tr>
                  <td colSpan='2'>Application Details</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{data.application.id}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{data.application.name}</td>
                </tr>
                <tr>
                  <th>Created at</th>
                  <td>{data.application.createdAt}</td>
                </tr>
                <tr>
                  <th>Updated at</th>
                  <td>{data.application.updatedAt}</td>
                </tr>

                <tr>
                  <th colSpan='2'>Tokens</th>
                </tr>
                { data.application.tokens.map((token) => (
                  <tr key={token.id}>
                    <td>
                      <Link to={`/tokens/${token.id}`}>
                        {`Token with ID ${token.id}`}
                      </Link>
                    </td>
                    <td>{token.token}</td>
                  </tr>
                ))}
                { data.application.tokens.length === 0
                  && (
                    <tr>
                      <td colSpan='2'>N/A</td>
                    </tr>
                  )}
              </tbody>
            </Table>
          )}
    </>
  )
}

export default Preview
