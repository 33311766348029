import React from 'react'
import Button from 'react-bootstrap/Button'

function CopyContainer({ target }) {
  const targetTextInput = React.useRef()

  const copyToClipboard = () => {
    targetTextInput.current.select()

    document.execCommand('copy')
  }

  const copy = () => {
    if (global.navigator.clipboard) {
      global.navigator.clipboard.writeText(target)
    } else {
      copyToClipboard()
    }
  }

  return (
    <div className='copy-container'>
      <span>{target}</span>
      <Button
        variant='light'
        size='sm'
        onClick={() => copy()}
      >
        copy
        <input
          type='text'
          style={{ position: 'fixed', left: '-9999px', top: '-9999px' }}
          defaultValue={target}
          ref={targetTextInput}
        />
      </Button>
    </div>
  )
}

export default CopyContainer
