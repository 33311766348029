import React from 'react'
import Badge from 'react-bootstrap/Badge'

const statusToBadgeClass = {
  completed: 'success',
  error: 'danger',
  executing: 'warning'
}

function StatusBadge({ status }) {
  return (
    <Badge bg={statusToBadgeClass[status]}>{status}</Badge>
  )
}

export default StatusBadge
