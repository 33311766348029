import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import ApplicationsSelect from '@components/applications/select'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import CopyContainer from '@components/copy-container'
import DatetimeField from '@components/datetime-field'
import Form from 'react-bootstrap/Form'
import Heading from '@components/heading/heading'
import Select from 'react-select'
import GET_APPLICATIONS_FOR_SELECT from '@graphql/queries/get-applications-for-select'
import CREATE_TOKEN from '@graphql/mutations/create-token'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import ToastMessage from '@components/ToastMessage/ToastMessage'

function New() {
  const navigate = useNavigate()
  const [token, setToken] = useState({ applicationId: null, activeUntil: null })
  const [createToken, { data, loading, error }] = useMutation(CREATE_TOKEN, {
    onCompleted() { toast(<ToastMessage message='Token Created' />) },
    onError() { }
  })

  return (
    <>
      <Heading heading='Create new token' />
      { error && (
        <Alert variant='danger'>{error.message}</Alert>
      ) }
      { data?.createToken?.token
        && (
          <Alert variant='success'>
            <p>
              {`The token was successfully created!
                Please make sure that you have copied its value! You won't be able to retrieve it once you leave the page!
               `}
            </p>
            <CopyContainer target={data.createToken.token} />
          </Alert>
        ) }
      <Card>
        <div className='heading-6'>New Token Configuration</div>
        <Card.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Application</Form.Label>
            <ApplicationsSelect
              value={token.applicationId}
              onChange={(event) => setToken({ ...token, applicationId: event.value })}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Active until</Form.Label>
            <DatetimeField
              name='activeUntil'
              onChange={(name, value) => setToken({ ...token, [name]: value })}
            />
          </Form.Group>

        </Card.Body>
        <Card.Footer className='text-center'>
          <Button
            variant='success'
            onClick={() => { createToken({ variables: { ...token } }) }}
            disabled={loading}
          >
            Submit
            {loading && (
              <Spinner as='span' animation='border' role='status' size='sm' className='ms-1' />
            )}
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default New
