import { gql } from '@apollo/client'
import ITEMS_PER_PAGE from '@utils/constants'

const GET_TOKENS = gql`
  query GetTokens($filters: JSON) {
    tokens(filters: $filters) {
      collection {
        id
        token
        application {
          id
          name
        }
        active
        activeUntil
        createdAt
        updatedAt
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_TOKENS
