import { gql } from '@apollo/client'

const GET_APPLICATION = gql`
  query GetApplication($id: ID!) {
    application(id: $id) {
      id
      name
      tokens {
        id
        token
      }
      createdAt
      updatedAt
    }
  }
`

export default GET_APPLICATION
