import { gql } from '@apollo/client'
import ITEMS_PER_PAGE from '@utils/constants'

const GET_SAGA_TRANSACTIONS = gql`
  query GetSagaTransactions($filters: JSON) {
    sagaTransactions(filters: $filters) {
      collection {
        id
        type
        status
        operation {
          id
          name
          merchantName
        }
        createdObjectId
        createdObjectUrl
        createdAt
        updatedAt
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_SAGA_TRANSACTIONS
