import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import User from '@utils/storage/user'
import TableLoader from '@components/TableLoader/TableLoader'

function ApplicationsTable({ applications, totalCount, loading }) {
  return (
    <>
      <div className='total-count-wrapper'>{`Applications count: ${totalCount}`}</div>
      <Table responsive bordered hover striped>
        {loading ? (
          <TableLoader />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th className='table-col-sm'>ID</th>
                <th>Name</th>
                <th>Created at</th>
                <th>Updated at</th>
              </tr>
            </thead>
            <tbody>
              { applications.map((application) => (
                <tr key={application.id}>
                  <td>
                    <DropdownButton variant='outline-secondary' title='Actions' size='sm'>
                      <Link to={`${application.id}`} className='dropdown-item'>Show</Link>
                      { User.hasMasterPrivileges() && <Link to={`/applications/${application.id}/edit`} className='dropdown-item'>Edit</Link> }
                    </DropdownButton>
                  </td>
                  <td>{application.id}</td>
                  <td>
                    <Link to={`/applications/${application.id}`}>
                      {application.name}
                    </Link>
                  </td>
                  <td>{application.createdAt}</td>
                  <td>{application.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  )
}

ApplicationsTable.defaultProps = {
  totalCount: 0
}

export default ApplicationsTable
