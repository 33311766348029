const TYPES = [
  'createCardPresentMerchant',
  'createCardNonPresentMerchant'
].map((type) => ({ value: type, label: type }))

const STATUSES = [
  'completed',
  'executing',
  'error'
].map((type) => ({ value: type, label: type }))

const SEARCH_FILTERS = {
  name_eq: '',
  application_id_eq: '',
  status_eq: '',
  created_at_gteq: '',
  created_at_lteq: '',
  merchant_name_eq: ''
}

export default { TYPES, SEARCH_FILTERS, STATUSES }
