import React from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import Heading from '@components/heading/heading'
import NavigateButton from '@components/button/navigate'
import Pagination from '@components/pagination'
import Search from '@components/saga-transactions/search'
import Table from '@components/saga-transactions/table'
import GET_SAGA_TRANSACTIONS from '@graphql/queries/get-saga-transactions'
import Error from '@components/Error/Error'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_SAGA_TRANSACTIONS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <Heading heading='Saga Transactions' />
      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.sagaTransactions?.metadata}
          />
          <Table
            sagaTransactions={data?.sagaTransactions?.collection || []}
            totalCount={data?.sagaTransactions?.metadata?.totalCount}
            loading={loading}
          />
        </>
      )}
    </>
  )
}

export default List
