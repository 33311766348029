import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import ApplicationsSelect from '@components/applications/select'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Select from 'react-select'
import SearchActions from '@components/search/actions'
import token from '@utils/constants/token'

function Search({ refetch, searchFilters, setSearchParams }) {
  const [filters, setFilters] = useState({
    ...token.SEARCH_FILTERS,
    ...searchFilters
  })
  const valueForSelect = (options, selected) => (
    options.filter(({ value }) => value === selected)
  )

  return (
    <Card>
      <Card.Header>Search</Card.Header>
      <Card.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={3}>Application</Form.Label>
          <Col sm={6}>
            <ApplicationsSelect
              name='application_id_eq'
              value={filters.application_id_eq}
              onChange={(event) => setFilters({ ...filters, application_id_eq: event.value })}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(token.SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default Search
