import { gql } from '@apollo/client'
import ITEMS_PER_PAGE from '@utils/constants'

const GET_OPERATIONS = gql`
  query GetOperations($filters: JSON) {
    operations(filters: $filters) {
      collection {
        id
        name
        status
        application {
          id
          name
        }
        request {
          id
        }
        merchantName
        createdAt
        updatedAt
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_OPERATIONS
