import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Table, Accordion, Alert, Spinner } from 'react-bootstrap'
import GET_EVENT_LOGS from '@graphql/queries/get-event-logs'

function EventLogsTable({ objectId, objectType }) {
  const [isOpen, setIsOpen] = useState(false)
  const [fetchEventLogs, { data, error, loading, called }] = useLazyQuery(GET_EVENT_LOGS, {
    variables: { objectId, objectType },
    onCompleted() { setIsOpen(true) },
    onError() { setIsOpen(true) }
  })

  const open = () => (called ? setIsOpen(!isOpen) : fetchEventLogs())

  function renderRow(eventLog) {
    const { id, createdAt, title, message } = eventLog

    return (
      <tr key={id}>
        <td>{id}</td>
        <td>{createdAt}</td>
        <td>{title}</td>
        <td>
          <code>
            <pre className='mb-0'>
              {message}
            </pre>
          </code>
        </td>
      </tr>
    )
  }

  return (
    <Accordion className='mb-5'>
      <Accordion.Item eventKey='0'>
        <Accordion.Button onClick={() => open()}>
          Event Logs &nbsp;{ loading && <Spinner animation='border' size='sm' variant='dark' /> }
        </Accordion.Button>
        <Accordion.Collapse eventKey='0' in={isOpen}>
          <div className='accordion-body'>
            {error ? (
              <Alert key='error' variant='danger'>
                Something went wrong! Please, contact tech support!
              </Alert>
            ) : (
              <div className='table-responsive-margin-b-0'>
                {data && data.eventLogs.length ? (
                  <Table responsive bordered hover striped>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Created at</th>
                        <th>Title</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.eventLogs.map((eventLog) => renderRow(eventLog))}
                    </tbody>
                  </Table>
                )
                  : (
                    <Alert key='warning' variant='warning'>
                      There are no Event Logs associated
                    </Alert>
                  )}
              </div>
            )}
          </div>
        </Accordion.Collapse>
      </Accordion.Item>
    </Accordion>
  )
}

export default EventLogsTable
