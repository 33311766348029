import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import User from '@utils/storage/user'
import ToastMessage from '@components/ToastMessage/ToastMessage'

function RestrictPrivateRoute() {
  const navigate = useNavigate()

  useEffect(() => {
    if (!User.hasMasterPrivileges()) {
      toast(<ToastMessage message='You are not authorised to access this page.' variant='danger' />)

      navigate('/')
    }
  }, [])

  return <Outlet />
}

export default RestrictPrivateRoute
