import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Accordion from 'react-bootstrap/Accordion'
import copy from '@utils/copy'
import downloadjson from '@utils/download-json'
import Table from 'react-bootstrap/Table'
import TraceableLink from '@components/requests/traceable-link'
import Heading from '@components/heading/heading'
import GET_REQUEST from '@graphql/queries/get-request'
import Button from 'react-bootstrap/Button'
import Error from '@components/Error/Error'

function Preview() {
  const { requestId } = useParams()
  const { data: { request } = {}, error, loading } = useQuery(GET_REQUEST, {
    variables: { id: requestId }
  })

  return (
    <>
      <Heading
        heading='Request Details'
        toolbar={(
          <>
            <Button size='sm' variant='primary' className='me-2' onClick={() => copy(JSON.stringify(request?.payload, null, 2))}> Copy Payload </Button>
            <Button size='sm' variant='primary' onClick={() => downloadjson(request)}> Download Payload </Button>
          </>
      )}
      />
      { error && (
        <Error error={error} />
      )}
      { request
        && (
          <>
            <br />
            <Table responsive bordered hover striped>
              <thead>
                <tr align='center'>
                  <td colSpan='4'>
                    <strong>Traceable: </strong>
                    <TraceableLink traceableId={request.traceableId} traceableType={request.traceableType} />
                  </td>
                </tr>
                <tr align='center'>
                  <td colSpan='4'>
                    <strong>Request</strong>
                  </td>
                </tr>
                <tr>
                  <td>ID</td>
                  <td>Payload</td>
                  <td>Created at</td>
                  <td>Updated at</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{request.id}</td>
                  <td>
                    <pre>{JSON.stringify(request.payload, null, 2)}</pre>
                  </td>
                  <td>{request.createdAt}</td>
                  <td>{request.updatedAt}</td>
                </tr>
              </tbody>
            </Table>

            <Table responsive bordered hover striped>
              <thead>
                <tr align='center'>
                  <td colSpan='4'>
                    <strong>Responses</strong>
                  </td>
                </tr>
                <tr>
                  <td>ID</td>
                  <td>Payload</td>
                  <td>Created at</td>
                  <td>Updated at</td>
                </tr>
              </thead>
              <tbody>
                { request.responses.map((response) => (
                  <tr key={response.id}>
                    <td>{response.id}</td>
                    <td>
                      <pre>{JSON.stringify(response.payload, null, 2)}</pre>
                    </td>
                    <td>{response.createdAt}</td>
                    <td>{response.updatedAt}</td>
                  </tr>
                )) }
              </tbody>
            </Table>
          </>
        )}
    </>
  )
}

export default Preview
