import React from 'react'
import Badge from 'react-bootstrap/Badge'

const statusToBadgeClass = {
  new: 'light',
  creating: 'warning',
  failed_creating: 'primary',
  created: 'success',
  rejecting: 'danger',
  failed_rejecting: 'dark',
  rejected: 'secondary',
  ignored: 'info'
}

const statusToTextProp = {
  new: 'dark'
}

function StatusBadge({ status }) {
  return (
    <Badge
      bg={statusToBadgeClass[status]}
      text={statusToTextProp[status]}
    >
      {status}
    </Badge>
  )
}

export default StatusBadge
