import React from 'react'
import copy from '@utils/copy'
import downloadJson from '@utils/download-json'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import TraceableLink from '@components/requests/traceable-link'
import Button from 'react-bootstrap/Button'
import TableLoader from '@components/TableLoader/TableLoader'

function RequestsTable({ requests, totalCount, loading }) {
  return (
    <>
      <div className='total-count-wrapper'>{`Requests count: ${totalCount}`}</div>
      <Table responsive bordered hover striped>
        {loading ? (
          <TableLoader />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th className='table-col-sm'>ID</th>
                <th>Traceable</th>
                <th>Payload</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              { requests.map((request) => (
                <tr key={request.id}>
                  <td>
                    <DropdownButton variant='outline-secondary' title='Actions' size='sm'>
                      <Link to={`${request.id}`} className='dropdown-item'>Show</Link>
                      <Button
                        className='dropdown-item'
                        onClick={() => copy(JSON.stringify(request?.payload, null, 2))}
                      >
                        Copy Payload
                      </Button>
                      <Button
                        className='dropdown-item'
                        onClick={() => downloadJson(request)}
                      >
                        Download Payload
                      </Button>
                    </DropdownButton>
                  </td>
                  <td>
                    <Link to={`${request.id}`}>
                      {request.id}
                    </Link>
                  </td>
                  <td>
                    <TraceableLink traceableId={request.traceableId} traceableType={request.traceableType} />
                  </td>
                  <td>
                    <pre>
                      {JSON.stringify(request.payload, null, 2) }
                    </pre>
                  </td>
                  <td>{request.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  )
}

RequestsTable.defaultProps = {
  totalCount: 0
}

export default RequestsTable
