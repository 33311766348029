import { gql } from '@apollo/client'

const CREATE_APPLICATION = gql`
  mutation CreateApplication($name: String!) {
    createApplication(name: $name) {
      id
      name
      tokens {
        id
      }
      createdAt
      updatedAt
    }
  }
`

export default CREATE_APPLICATION

