import React from 'react'

function downloadJson(request) {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent((JSON.stringify(request.payload, null, 2)))}`
  const link = document.createElement('a')

  link.href = jsonString
  link.download = `request_payload_${request.id}.json`

  link.click()
}

export default downloadJson
