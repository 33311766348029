import React from 'react'
import Badge from 'react-bootstrap/Badge'

function StatusBadge({ active }) {
  const [bg, title] = active ? ['success', 'active'] : ['danger', 'expired']

  return <Badge bg={bg}>{title}</Badge>
}

export default StatusBadge
