import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import StatusBadge from '@components/operations/status-badge'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import TableLoader from '@components/TableLoader/TableLoader'

const statusToBadgeClass = {
  completed: 'success',
  error: 'danger',
  executing: 'warning'
}

function OperationsTable({ operations, totalCount, loading }) {
  return (
    <>
      <div className='total-count-wrapper'>{`Operations count: ${totalCount}`}</div>
      <Table responsive bordered hover striped>
        {loading ? (
          <TableLoader />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th className='table-col-sm'>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Application</th>
                <th>Merchant</th>
                <th>Created at</th>
                <th>Updated at</th>
              </tr>
            </thead>
            <tbody>
              { operations.map((operation) => (
                <tr key={operation.id}>
                  <td>
                    <DropdownButton variant='outline-secondary' title='Actions' size='sm'>
                      <Link to={`/operations/${operation.id}`} className='dropdown-item'>
                        View Details
                      </Link>
                      { operation.request
                          && (
                            <Link to={`/requests/${operation.request.id}`} className='dropdown-item'>
                              View Log
                            </Link>
                          ) }
                    </DropdownButton>
                  </td>
                  <td>{operation.id}</td>
                  <td>
                    <Link to={`/operations/${operation.id}`}>
                      {operation.name}
                    </Link>
                  </td>
                  <td>
                    <StatusBadge status={operation.status} />
                  </td>
                  <td>
                    <Link to={`/applications/${operation.application.id}`}>
                      {operation.application.name}
                    </Link>
                  </td>
                  <td>{operation.merchantName}</td>
                  <td>{operation.createdAt}</td>
                  <td>{operation.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  )
}

OperationsTable.defaultProps = {
  totalCount: 0
}

export default OperationsTable
