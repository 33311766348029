import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import Error from '@components/Error/Error'
import ToastMessage from '@components/ToastMessage/ToastMessage'
import Heading from '@components/heading/heading'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import CREATE_APPLICATION from '@graphql/mutations/create-application'
import Spinner from 'react-bootstrap/Spinner'

function New() {
  const navigate = useNavigate()
  const [applicationName, setApplicationName] = useState('')
  const [createApplication, { data, loading, error }] = useMutation(CREATE_APPLICATION, {
    onCompleted(data) {
      if (data.createApplication) {
        navigate(`/applications/${data.createApplication.id}`)
        toast(<ToastMessage message={`Application ${data.createApplication.name} was successfully created!`} />)
      }
    },
    onError() { }
  })

  return (
    <>
      <Heading heading='New Application' />
      { error && (
        <Error error={error} />
      ) }
      <Card>
        <div className='heading-6'>Application Configuration</div>

        <Card.Body>
          <Form.Group className='mb-3' controlId='formGroupName'>
            <Form.Label>Name</Form.Label>
            <Form.Control name='name' onChange={(event) => setApplicationName(event.target.value)} />
          </Form.Group>
        </Card.Body>
        <Card.Footer className='text-center'>
          <Button
            variant='success'
            disabled={loading}
            onClick={() => createApplication({ variables: { name: applicationName } })}
          >
            Submit
            {loading && (
              <Spinner as='span' animation='border' role='status' size='sm' className='ms-1' />
            )}
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default New
