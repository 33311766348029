const TYPES = [
  'CompanySagaTransaction',
  'MerchantAdminUserSagaTransaction',
  'MerchantSagaTransaction',
  'MidSagaTransaction',
  'ResellerSagaTransaction'
].map((type) => ({ value: type, label: type }))

const STATUSES = [
  'new',
  'creating',
  'failed_creating',
  'created',
  'rejecting',
  'failed_rejecting',
  'rejected',
  'ignored'
].map((type) => ({ value: type, label: type }))

const SEARCH_FILTERS = {
  type_eq: '',
  status_eq: '',
  operation_name_eq: '',
  operation_id_eq: '',
  created_at_gteq: '',
  created_at_lteq: '',
  operation_merchant_name_eq: ''
}

export default { TYPES, SEARCH_FILTERS, STATUSES }
