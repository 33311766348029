import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import ExternalCreatedObjectLink from '@components/saga-transactions/external-created-object-link'
import Heading from '@components/heading/heading'
import SagaTransactionHierarchyTree from '@components/saga-transaction-hierarchy/tree'
import EventLogsTable from '@components/event-logs/table'
import StatusBadge from '@components/saga-transactions/status-badge'
import Table from 'react-bootstrap/Table'
import Error from '@components/Error/Error'
import GET_SAGA_TRANSACTION from '@graphql/queries/get-saga-transaction'

function Preview() {
  const { sagaTransactionId } = useParams()
  const { data, error, loading } = useQuery(GET_SAGA_TRANSACTION, {
    variables: { id: sagaTransactionId }
  })

  return (
    <>
      <Heading heading='Saga Transaction Details' />
      { error && (
        <Error error={error} />
      )}
      { data?.sagaTransaction
          && (
            <>
              <SagaTransactionHierarchyTree
                operationId={data.sagaTransaction.operation?.id}
                sagaTransactionId={Number(sagaTransactionId)}
              />
              <br />
              <div className='table-responsive-margin-b-0'>
                <Table responsive bordered hover striped>
                  <thead>
                    <tr>
                      <td colSpan='2'>Transaction Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{data.sagaTransaction.id}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{data.sagaTransaction.type}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <StatusBadge status={data.sagaTransaction.status} />
                      </td>
                    </tr>
                    <tr>
                      <th>Operation</th>
                      <td>
                        <Link to={`/operations/${data.sagaTransaction.operation.id}`}>
                          {data.sagaTransaction.operation.name}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Request</th>
                      <td>
                        { data.sagaTransaction.request && (
                        <Link to={`/requests/${data.sagaTransaction.request.id}`}>
                          {`Request (${data.sagaTransaction.request.id})`}
                        </Link>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Created object</th>
                      <td><ExternalCreatedObjectLink sagaTransaction={data.sagaTransaction} /></td>
                    </tr>
                    <tr>
                      <th>Created at</th>
                      <td>{data.sagaTransaction.createdAt}</td>
                    </tr>
                    <tr>
                      <th>Updated at</th>
                      <td>{data.sagaTransaction.updatedAt}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <br />
              <EventLogsTable objectId={sagaTransactionId} objectType='SagaTransaction' />
            </>
          )}
    </>
  )
}

export default Preview
