import React from 'react'
import { Link } from 'react-router-dom'

const pathTo = (traceableType) => (traceableType === 'SagaTransaction'
  ? 'transactions' : 'operations')

function TraceableLink({ traceableId, traceableType }) {
  return (
    <Link to={`/${pathTo(traceableType)}/${traceableId}`}>
      {`${traceableType} (${traceableId})`}
    </Link>
  )
}

export default TraceableLink
