import { gql } from '@apollo/client'

const GET_SAGA_TRANSACTION = gql`
  query GetSagaTransaction($id: ID!) {
    sagaTransaction(id: $id) {
      id
      type
      status
      operation {
        id
        name
      }
      request {
        id
      }
      createdObjectId
      createdObjectUrl
      createdAt
      updatedAt
    }
  }
`

export default GET_SAGA_TRANSACTION
