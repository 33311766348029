import React from 'react'
import { Outlet } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navigation from '@components/navigation/navigation'

function Layout() {
  return (
    <>
      <Navigation />
      <Container fluid='md'>
        <Outlet />
      </Container>
    </>
  )
}

export default Layout
