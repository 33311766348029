import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Actions({ onSearch, onReset }) {
  return (
    <Form.Group as={Row} className='mb-3'>
      <Col sm={{ span: 6, offset: 3 }}>
        <Button
          size='sm'
          variant='light'
          onClick={onReset}
        >
          Reset
        </Button>
        <Button
          size='sm'
          variant='primary'
          className='pull-right'
          onClick={onSearch}
        >
          Search
        </Button>
      </Col>
    </Form.Group>
  )
}

export default Actions
