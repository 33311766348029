import React from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import Heading from '@components/heading/heading'
import NavigateButton from '@components/button/navigate'
import Pagination from '@components/pagination'
import Search from '@components/applications/search'
import Table from '@components/applications/table'
import GET_APPLICATIONS from '@graphql/queries/get-applications'
import User from '@utils/storage/user'
import Error from '@components/Error/Error'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, error, loading, refetch, fetchMore } = useQuery(GET_APPLICATIONS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <Heading
        heading='Applications'
        toolbar={User.hasMasterPrivileges() && <NavigateButton to='/applications/new'>New application</NavigateButton>}
      />

      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.applications?.metadata}
          />
          <Table
            totalCount={data?.applications?.metadata?.totalCount}
            applications={data?.applications?.collection || []}
            loading={loading}
          />
        </>
      )}
    </>
  )
}

export default List
